import "../i18n";
import React, { useState, useEffect, createRef } from 'react';
import Cookies from 'js-cookie';
import ReCAPTCHA from "react-google-recaptcha";

import DynamicLabelInput from './DynamicLabelInput';
import SubscribeListContainer from './SubscribeListContainer';
import { isValidEmail } from '../util/isValidEmail';
import { isInDarkMode } from "../util/isInDarkMode";


function SubscribeForm() {
  const [displayLists, setDisplayLists] = useState(false);
  const [signupDisabled, setSignupDisabled] = useState(true);
  const [formSuccess, setFormSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [formData, setFormData] = useState({
    firstname: '',
    e_mail: '',
    lists: [],
    permission: true,
    recaptcha: '',
  });
  reCaptchaRef = createRef();
  const reCaptchaPublicKey = window.RECAPTCHA_PUBLIC_KEY;
  const csrftoken = Cookies.get('csrftoken');
  const darkMode = isInDarkMode();


  // checks if lists can be displayed
  useEffect(() => {
    if (formData.firstname !== '' && isValidEmail(formData.e_mail) && formData.permission == true) {
      setSignupDisabled(false);
    } else {
      setSignupDisabled(true);
    }
  }, [formData]);


  function handleChange(event) {
    let { name, value } = event.target;
    if (name === "permission") {
      value = event.target.checked;
    }
    const updatedFormData = {
      ...formData,
      [name]: value,
    };
    setFormData(updatedFormData);
  }


  function handleCheckboxChange(event) {
    const { name, value, checked } = event.target;
    setFormData(prevFormData => {
      const updatedLists = checked 
        ? [...prevFormData.lists, value] 
        : prevFormData.lists.filter(item => item !== value);

      return {
        ...prevFormData,
        lists: updatedLists
      };
    });
  }


  function signupClickHandler() {
    setDisplayLists(true);
  }

  async function formSubmitHandler(event) {
    event.preventDefault();

	const extraTags = window.SITE_ACRONYM == "ROH" ? ["New Subscriber"] : [];
	const tags = formData.lists.concat(extraTags);
    try {
      const response = await fetch("/mailinglists/_api/create-contact/", {
        method: "POST",
        body: JSON.stringify({
          email: formData.e_mail, 
          first_name: formData.firstname, 
          last_name: "",
          tags: tags,
		      captcha: formData.recaptcha,
        }), 
        headers: {
          "Content-Type": "application/json", 
          "X-CSRFToken": csrftoken,
        }
      });

      if (!response.ok) {
        const errorResponse = await response.json();
        let errorMessages = [];
        if (errorResponse.first_name) {
          errorMessages.push(`${window.gettext("First Name")}: ${errorResponse.first_name[0]}`);
        }
        if (errorResponse.email) {
          errorMessages.push(`${window.gettext("Email")}: ${errorResponse.email[0]}`);
        }
        if (errorResponse.captcha) {
          errorMessages.push(`Captcha: ${errorResponse.captcha[0]}`);
        }
        setFormErrors(errorMessages);
      } else {
        setFormSuccess(true);
        setFormErrors([]);
      }
      
    } catch (error) {
      console.log("formSubmitHandler error: " + error);
      setFormSuccess(false);
    }
  }

  function onChangeReCaptcha(value) {
    const updatedFormData = {
      ...formData,
      recaptcha: value,
    };
    setFormData(updatedFormData);
  }

  if (formSuccess){
    return (
      <>
        <span dangerouslySetInnerHTML={{
          __html: window.gettext('<h3>Thanks for Signing Up</h3><p>We are so glad you joined.</p><p>You are part of community of tens of thousands of women seeking the Lord together. Our desire is that you experience the freedom, fullness, and fruitfulness that can only be found in Christ.</p>')
        }} />
      </>
    );
  }

  return (
    <>
      <h3>
        <span dangerouslySetInnerHTML={{
          __html: window.gettext("Stay Connected with <em>Revive Our Hearts</em>.")
        }} />
      </h3>
      
      {formErrors.length > 0 && <ul style={styles.formErrors}>
        {formErrors.map((error, index) => (
        <li key={index}>
          Error: {error}
        </li> 
        ))}
      </ul>
      }
      <form className='dynamic-labels'>
      <div className="form-group-row form-group-row--three">
        <div className="form-group mb-0 _form_element _x03066740 ">
          <DynamicLabelInput 
            labelText={window.gettext("First Name")}
            type="text"
            name="firstname"
            id="firstname"
            value={formData.firstname}
            onChange={handleChange}
          />
        </div>
        <div className="form-group mb-0 _form_element _x95938857 ">
          <DynamicLabelInput 
            labelText={window.gettext("Email")}
            type="email"
            name="e_mail"
            id="e_mail"
            value={formData.e_mail}
            onChange={handleChange}
          />
        </div>
        {!displayLists && <button onClick={signupClickHandler} className="btn select" disabled={signupDisabled}>{gettext("Sign Up")} <svg className="icon" width="14" height="14"><use xlinkHref="#check"/></svg></button>}
      </div>
      </form>
      {displayLists && <div>
        <div className="mb-1 _form_element _x78356968 _full_width">
          <p>
            <span dangerouslySetInnerHTML={{
              __html: window.gettext("All signups will receive news and updates from <em>Revive&nbsp;Our&nbsp;Hearts</em>.<br />Select any additional updates that you'd like to&nbsp;receive.")
            }} />
          </p>
          <SubscribeListContainer onChange={handleCheckboxChange} />          
          <div style={styles.agreementContainer}>
            <div className="_row _checkbox-radio">
              <input id="field_38" type="checkbox" name="permission" value="permission" checked={formData.permission} style={styles.agreementInput} onChange={handleChange} />
              <label style={styles.agreementLabel} htmlFor="field_38">
                <span dangerouslySetInnerHTML={{
                  __html: window.gettext("<strong>Yes</strong>, I want to receive these resources from <em>Revive Our Hearts</em>. By signing up for these lists, you are agreeing to our <a href=\"/privacy-statement/\" target=\"_blank\">privacy policy</a> and <a href=\"/terms-of-service/\" target=\"_blank\">terms of service</a>.")
                }} />
              </label>
            </div>
          </div>
        </div>
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={reCaptchaPublicKey}
          onChange={onChangeReCaptcha}
          theme={darkMode ? "dark" : "light"}
        />
        <div className="mb-1 mt-1 _button-wrapper _full_width">
          <button id="_form_66_submit" className="_submit btn btn-primary btn-lg" type="submit" disabled={signupDisabled} onClick={formSubmitHandler}>{window.gettext('Subscribe Now')}</button>
        </div>
      </div>}
    </>
  );
}

export default SubscribeForm;

const styles = {
  formErrors: {
    borderColor: "red", 
    color: "red",
  },
  agreementContainer: { 
    borderTop: "1px solid var(--border-color)", 
    paddingTop: "10px", 
    marginTop: "10px",
  },
  agreementLabel: {
    display: "block",
    marginLeft: "33px", 
    fontWeight: "normal"
  },
  agreementInput: { 
    position: "absolute",
  }
};
