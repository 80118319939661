import React, { useState } from 'react';

function DynamicLabelInput({
  labelText,
  type,
  name,
  id,
  value,
  onChange,
  disabled
}) {
  const [isFocused, setIsFocused] = useState(false);
  const isActive = isFocused || value !== '';

  return (
    <div className="form-group _field-wrapper">
      <label
        className={`text-label ${isActive ? "active" : ""}`.trim()}
        htmlFor={id}
      >
        {labelText}
      </label>
      <input
        className="form-control"
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        disabled={disabled}
        pattern={type === "tel" ? "[0-9]{3}-[0-9]{3}-[0-9]{4}" : null}
      />
    </div>
  );
}

export default DynamicLabelInput;

